.Bottom {
    width: 100%;
    height: 288px;
    z-index: 99;
    background-color: rgb(39 51 67);
}

.Bottom-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-more {
    display: flex;
    padding: 40px 140px;
}

.about-more-item {
    display: flex;
    flex-direction: column;
    width: 200px;
    

}

.ttype{

    display: flex;
}

.ttype span{
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    cursor: pointer;
}

.about-more-item p {
    color: #fff;
    font-size: 15px;
}

.about-more-item span {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    margin-top: 25px;
    cursor: pointer;
}

.icon-list {
    margin-top: 20px;
    display: flex;
}

.icon-list img {
    width: 30px;
    height: 30px;
    margin-right: 60px;
    cursor: pointer;
}


.content-layout{
    background-color: rgba(0, 0, 0, 0.1);
    height: 88px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    line-height: 88px;
    text-align: center;
}
