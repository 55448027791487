.H5sidebar{
    text-align: center;
}

.btn-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(243 247 255);
    border-radius: 0 0 12px 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
}

.btn-list-item{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-list-item img{
    width: 16px;
    height: 16px;
    margin-right: 21px;
}

.btn-list-item-left{
    display: flex;
    align-items: center;
    margin-left: 28px;
}

.btn-list-item-left img{
    width: 30px;
    height: 30px;
   
}

.btn-list-item-left p{
    color: rgba( 27 28 29 0.8);
    font-size: 16px;
}

.language-list{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    padding:  10px 0;
    background-color: rgb(243 247 255);
    border-radius: 12px 12px 0 0;
    position: fixed;
    bottom: 0;
}

.language-list p{
    color: rgba( 27 28 29 1);
    font-size: 18px;
}

.language-list button{
    background: linear-gradient(to right,rgb(24 87 255) ,rgb(108 78 255));
    width: 327px;
    height: 50px;
    border: none;
    color:#fff;
    border-radius: 12px;
    margin: 44px 0;
    font-size: 16px;
    font-weight: bold;

}

.language-list-item{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.language-list-item p{
    color: rgba( 27 28 29 0.8);
    font-size: 14px;
    margin-left: 14px;
}

.language-list-item img{
    width: 30px;
    height: 30px;
    margin-right: 14px;
}