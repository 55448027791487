.H5Account {
    width: 100%;
    background-color: rgb(247 248 255);
    display: flex;
    flex-direction: column;
    padding: 40px 0;
}

.H5Account-img{
    width: 160px;
    height: 160px;
    margin-left: 40px;
}

.H5Account-right{
    margin-top: 48px;
    margin-left: 32px;
    display: flex;
    flex-direction: column;
}

.H5Account-p1{
    color: #000;
    font-size: 20px;
    font-weight: bold;
}

.H5Account-p2{
    color: #000;
    font-size: 16px;
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 4px;
}

.H5Account-right span{
    display: block;
    color: #000;
    font-size: 14px;
    margin-top: 20px;
    width: 90%;
}

.H5Account-input{
    height: 32px;
    padding: 0 10px;
    width: 80%;
}

.H5Account-btn{
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd;
    width: 100px;
    height: 32px;
    border: none;
    border-radius: 4px;
    margin-top: 12px;
} 

.H5code-btn{
    background: none;
    border: none;
    position: absolute;
    color: #357ebd;
    width: 130px;
    margin-top: 12px;
    right: 60px;
    text-align: right;

}



