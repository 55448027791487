.Account {
    width: 100%;
    height: 500px;
    /* height: 681px; */
    /* z-index: 99; */
    background-color: rgb(247 248 255);
    padding-top: 140px;
    display: flex;


}

.Account-img{
    width: 320px;
    height: 320px;
}

.Account-right{
    margin-top: 48px;
    margin-left: 32px;
    display: flex;
    flex-direction: column;
}

.Account-p1{
    color: #000;
    font-size: 26px;
    font-weight: bold;
}

.Account-p2{
    color: #000;
    font-size: 22px;
    font-weight: bold;
    margin-top: 32px;
    margin-bottom: 4px;
}

.Account-right span{
    display: block;
    width: 400px;
    color: #000;
    font-size: 18px;
    margin-top: 20px;

}

.Account-input{
    width: 400px;
    height: 32px;
    padding: 0 10px;
}

.Account-btn{
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd;
    width: 100px;
    height: 32px;
    border: none;
    border-radius: 4px;
    margin-top: 12px;
}

.Account-col{
    display: flex;
    flex-direction: column;
}
 
.code-btn {
    background: none;
    border: none;
    position: relative;
    top: -24px;
    left: 280px;
    color: #357ebd;
    width: 130px;
    text-align: right;
}



