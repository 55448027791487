.H5rebate{
    text-align: center;
    background-color: rgb(247 248 255);

}

.H5rebate-top{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 320px;
    background-image: url(../assets/h5/rebate-bg.png);
    background-size: 100% 100%;
}
.H5rebate-top p{
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    margin-top: 12px;
}

.H5rebate-top span{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}


.H5rebate button{
    background: linear-gradient(to right,rgb(24 87 255) ,rgb(108 78 255));
    border-radius: 4px;
    width: 222px;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
}

.title{
    height: 48px;
    width: 100%;
    color: rgba(27, 28, 29, 0.8);
    font-size: 16px;
    line-height: 48px;
    margin: 14px 0;
}

.u-list{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.u-list-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 0 32px;
    min-height: 160px;
    margin: 10px 0;
    border-radius: 25px;

}

.u-list-item img{
    width: 110px;
    height: 110px;
}

.u-list-item p{
    color:rgb(28 28 32);
    font-size: 18px;
}

.details-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;

}

.details-list-item{
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details-list-item p{
    color:rgb(28 28 32);
    font-size: 20px;
    font-weight: bold;
}

.details-list-item img{
    width: 90px;
    height: 90px;
    margin: 10px 0;
}

.details-content{
    white-space:pre-wrap;
    font-size: 14px !important;
    width: 90%;
}