.Connect {
    width: 100%;
    padding-top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(247 248 255);

}

.Connect p {
    color: rgb(28 28 32);
    font-size: 48px;
    font-weight: bold;
}

.ContactList {
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.listItem {
    width: 312px;
    height: 320px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-decoration: none;
    cursor: pointer;
    background-color: #fff;
    margin-right: 32px
}


.listItem img {
    width: 110px;
    height: 110px;
}

.listItem p {
    font-size: 24px;
    color: rgb(28 28 32);
    margin: 0;
}

.listItem span {
    /* margin-top: 20px; */
    color: rgba(28, 28, 32, 0.8);
    font-size: 20px;
}

.listItem button {
    background-color: rgb(61 121 252);
    width: 150px;
    height: 46px;
    border-radius: 23px;
    color: #fff;
    font-size: 20px;
    border: none;

}


.introduce {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.introduce p {
    color: rgb(28 28 32);
    font-size: 48px;
}

.introduce span {
    width: 63%;
    color: rgba(28, 28, 32, 0.8);
    font-size: 18px;
    text-align: center;
    font-weight: 500;
}

.map {
    width: 1080px;
    height: 502px;
    margin-top: 40px;
}

.content {
    margin-top: 40px;
    width: 100%;
}

.content-item {
    width: 100%;
    height: 237px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.content-item .content-item-img {
    margin-left: 100px;
}

.content-item-info {
    width: 426px;
}

.content-item2 {
    width: 100%;
    height: 237px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(247 248 255);
}

.content-item2 .content-item-img {
    margin-right: 100px;
}

.content-item-info2 {
    width: 426px;
}

.content-item-img {
    width: 112px;
    height: 112px;
}

.content-item-info-p {
    color: rgb(28 28 32);
    font-size: 30px !important;
    margin-bottom: 10px;
}

.content-item-info-span {
    width: 372px;
    color: rgba(28 ,28 ,32, 0.6);
    font-size: 18px;
}