.Top {
    position: fixed;
    top: 0;
    width: 100%;
    height: 90px;
    background-color: rgba(24, 27, 34, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
}

.line {
    position: fixed;
    top: 80px;
    width: 100%;
    height: 10px;
    background-color: rgba(24, 27, 34, 1);
}

.top-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 164px;
}

.top-left img {
    width: 164px;
    height: 53px;
}

.language {
    width: 300px;
    height: 100px;
    display: flex;
    align-items: center;
    /* margin-right: 250px; */
    z-index: 99;
    cursor: pointer;
}

.language span {
    color: #fff;
    font-size: 18px;
    margin-left: 8px;

}

.languageBg {
    position: fixed;
    top: 60px;
    width: 202px;
    padding: 10px 0;
    background-color: rgb(247, 248, 255);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.language-item {
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    border-bottom: 1px solid rgba(234, 234, 234, 0.1);
}

.language-item img {
    width: 40px;
    height: 40px;
    margin-left: 15px;
}

.language-item p {
    font-size: 14px;
    color: rgb(28 28 32);
    margin: 0 0 0 5px !important;

}

.language-item-active {
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(234, 234, 234, 0.1);
    background-color: rgb(0 143 255);
}

.language-item-active img {
    width: 40px;
    height: 40px;
    margin-left: 15px;
}

.language-item-active p {
    font-size: 14px;
    color: #fff;
    margin: 0 0 0 5px !important;

}

.ant-tabs-nav-list {
    align-items: center;
    margin-top: 20px;
}

.ant-tabs-tab {
    margin-left: 100px;
    width: 120px;
    padding: 0 !important;

}

.ant-tabs-nav {
    position: inherit !important;
}

.ant-tabs-tab-btn {
    width: 120px;
    color: #fff !important;
    font-size: 16px;
    text-align: center;
}

.ant-tabs-tab-active {
    width: 120px;
    height: 28px;
    background-color: rgba(61, 121, 252, 0.5) !important;
    padding: 3px 0 !important;
    border-radius: 14px;
}

.ant-tabs-ink-bar {
    height: 0px !important;

}