.H5home {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(247 248 255);
}

.home-top {
    width: 100%;
    height: 320px;
    background-image: url(../assets/h5/top-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: rgb(247 248 255);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-top p {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
}

.h5-home-download {
    font-size: 22px;
    color: white;
    width: 70%;
    text-align: center;
    border-radius: 8.76px;
    height: 54px;
    line-height: 54px;
    margin-top: -27px;
    background: linear-gradient(to right, #6c4eff, #1857ff);
}

.h5-home-title {

    height: 58px;
    width: 100%;
    color: rgba(27, 28, 29, 0.8);
    font-size: 16px;
    text-align: center;
    line-height: 58px;
    font-weight: 600;
}

.bg {
    width: 100% !important;
    height: 100% !important;
    object-fit: fill;
}

.H5home p {
    color: #fff;
    font-size: 30px;
    margin: 0 0 10px 0;
}

.info {
    width: 100%;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list-item {

    min-height: 308px;
    background-color: #fff;
    border-radius: 25px;
    margin: 0 32px 32px 32px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;


}

.list-item img {
    width: 110px;
    height: 110px;
    margin: 25px 0;
}

.list-item p {
    color: rgb(28 28 32);
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.list-item span {
    color: rgba(28, 28, 32, 0.8);
    font-size: 16px;
    text-align: center;
    width: 95%;
}