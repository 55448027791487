.H5top {
    position: fixed;
    top: 0;
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: rgba(28, 34, 43, 1); */
    /* background: linear-gradient(to bottom, rgb(0 180 255), rgb(69 118 255)); */
    background: rgb(0 180 255);
    z-index: 99;
}

.H5top img {
    width: 125px;
    height: 40px;
    margin-left: 14px;
}

.H5top-right {
    display: flex;
    align-items: center;
}

.H5top-right img {
    width: 30px;
    height: 30px;
    margin-right: 14px;
}

.language-bg {
    width: 32px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
}