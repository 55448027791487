.H5member{
    text-align: center;
    background-color: rgb(247 248 255);
}


.H5member p{
    color: #fff;
    font-size: 30px;
    font-weight: bold;
}

.swiper-slide{

    display: flex !important;
    align-items: center;
    justify-content: center;

}

.swiper-slide-select{
    width: 166px;
    height: 190px;
    background-color: #EBF1FF;
    border: 2px solid #3D79FC;
    font-size: 30px;
    border-radius: 20px 60px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around
}

.swiper-slide-select p{
    margin: 0;
    color: rgb( 27, 28, 129);
    font-size: 20px;
   
}

.swiper-slide-normal{
    width: 146px;
    height: 160px;
    background-color: #fff;
    border: 2px solid #CBD8EB;
    font-size: 26px;
    border-radius: 20px 70px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around
}

.swiper-slide-normal p{
    margin: 0;
    color: rgb( 27, 28, 129);
    font-size: 16px;
    margin: 4px 0;
}

.discount-bg{
    /* width: 90px; */
    padding: 0 8px;
    height: 33px;
    background-color: rgb(61 121 252);
    border-radius: 18px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 33px;
    margin-bottom: 8px;
}

.h5recom{
    width: 100%;
    height: 28px;  
    display: flex;   
    position: absolute; 
    top: 0px;
    left: 12px;
}

.h5recom-no{
    width: 100%;
    height: 28px;  
    display: flex;   
    position: absolute; 
    top: 16px;
    left: 20px;
}

.h5-recom-txt{
    height: 100%;
    line-height: 28px;
    color: #fff;
    font-size: 14px;
    padding: 0 14px;
    border-radius: 8px 150px 150px 8px;
    background-image: linear-gradient(to right,  rgb( 255 95 135), rgb(255 146 96));
}

.member-content{
    width: 100%;
    height: 320px;
    background-image: url(../assets/h5/top-bg.png);
    background-size: 100% 100%;
}

.pay-layout{
    margin-top: 24px;
    height: 168px;
}

.pay-list{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 16px;
}

.pay-list-item{
    display: flex;
    align-items: center;
   flex-direction: column;
    justify-content: space-around;
}

.pay-list-item img{
    width: 60px;
    height: 60px;
}

.pay-list-item span{
    color: rgb( 27, 28, 29);
    font-size: 14px;
    margin: 10px 0 0 0;
}

