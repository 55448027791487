.InviteBg{

    margin-top: 90px;
    font-size: 0;
    width: 100%;
    height: 690px;
    background-image: url(../assets/bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: rgb(247 248 255);

}


.InviteBg-left{
    width: 50%;
    height: 100%;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.InviteBg-left p{
    color: #fff;
    font-size: 52px;
    font-weight: bold;
    margin-bottom: 32px;
}

.InviteBg-left span{
    color: #fff;
    font-size: 22px;
}

.btn{
    width: 400px;
    height: 74px;
    /* background-color: rgba(37, 233, 223,1); */
    background: linear-gradient(to right,rgb(24 87 255) ,rgb(108 78 255));
    border-radius: 12px;
    border: none;
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    margin-top: 34px;
}




