.Rebate{
    width: 100%;
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items:  center;
}

.acting{
    width: 100%;
    height: 690px;
    background-image: url(../assets/bg1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: rgb(247 248 255);

}

.right{
    float: right;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.right p{
    color: #fff;
    font-size: 61px;
    margin: 0;
    font-weight: 600;
    text-align: center;
}
.right span {
    font-size: 24px;
    color: #fff;
    margin-top: 12px;
}

.right button{
    background: linear-gradient(to right,rgb(24 87 255) ,rgb(108 78 255));
    border-radius: 12px;
    width: 400px;
    height: 74px;
    border: none;
    font-size: 30px;
    color: #fff;
    margin-top: 30px;
    cursor: pointer;
    font-weight: bold;
}

.rebate{
    padding-top: 50px;
    padding-bottom: 32px;
    background-color: rgba(247 248 255);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rebate p{
    color: rgb(28 28 32);
    font-size: 48px;
    margin: 0;
    font-weight: bold;
}

.rebate-list{

    margin-top: 50px;
    display: grid;
    grid-template-columns: 312px 312px 312px;
    grid-template-rows: 220px 220px;
    gap: 32px;
}

.rebate-list-item{
  
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
}

.rebate-list-item img{
    width: 110px;
    height: 110px;
}
.rebate-list-item span{
    color: rgb(28 28 32);
    font-size: 18px;
    text-align: center;
}

.details{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details-item{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.details-item-left{
    margin-left: 24%;
    display: flex;
    flex-direction: column;
}


.details-item-title{
    color: rgb(28 28 32);
    font-size: 30px;
    margin: 0;
    font-weight: bold;
}

.details-item-content{
    color: rgb(28 28 32);
    font-size: 18px;
    white-space:pre-wrap;
    margin-top: 34px;
}

.details-item img{
    margin-right: 24%;
    width: 180px;
    height: 180px;
}