.Advantagelayout{
    /* margin-top: 50px; */
    padding-top: 50px;
    background-color: rgb(247 248 255);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Advantagelayout p{
    color: rgb(28 28 32);
    font-size: 48px;
    font-weight: bold;
    margin: 0;
}

.Advantagelayout span{
    color: rgba(255 ,255 ,255, 0.6);
    font-size: 30px;
    margin-top: 40px;
    white-space:pre-wrap;
}

.container {
    display: grid;
    justify-content: center;
    grid-template-columns: 312px 312px 312px;
    grid-template-rows: 380px 380px;
    gap: 32px;

}

.item {
    background-color: #fff;
    width: 312px;
    height: 380px;
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    margin-top: 32px;
    border-radius: 25px;
    align-items: center;
}

.item img{
    width: 110px;
    height: 110px;
    margin-top: 45px;
    margin-bottom: 25px;
}

.item span{
    margin-top: 20px;
    width: 95%;
    color: rgba(28 ,28 ,32, 0.8);
    font-size: 16px;
    text-align: center;
}

.item p{
  
    color: rgb(28 28 32);
    font-size: 24px;
    text-align: center;
}


.device-bg{
    margin-top: 50px;
    padding: 50px 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.device-bg span{
    margin-top: 10px;
    color: rgba(28 ,28 ,32, 0.8);
    font-size: 18px;
}

.device-list{
    display: flex;
    margin-top: 51px;
}

.device-list-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    height: 165px;
    border: 2px solid rgba(135, 128, 128, 0.2);
    border-radius: 12px;
    margin: 0 47px;
    cursor: pointer;
    opacity: 0.5;
}
.device-list-item:hover{
    opacity: 1;
}

.device-list-item img{
    width: 90px;
    height: 90px;
    margin-top: 12px;
}



.device-list-item span{
    margin: 0;
    font-size: 24px;
    color: rgb(28 28 32)
    
}

.free-tips{
    width: 35%;
    color: rgb(28 28 32);
    font-size: 18px;
    text-align: center;
    margin-top: 35px;
}


.free-layout{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 72px;

}

.free-layout img{
    width: 200px;
    height: 200px;
}

.free-layout-right {
    /* width: 25%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.free-layout-right p{
    width: 100%;
    color: rgb(28 28 32);
    font-size: 40px;
    text-align: center;
}
.free-layout-right span{
    width: 80%;
    color: rgba(28 ,28 ,32, 0.6);
    font-size: 18px;
    text-align: center;
}




