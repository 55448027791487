.Download{
    margin-top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}   


.top-layout{

    width: 100%;
    height: 690px;
    background-image: url(../assets/bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.Download-right{
    width: 50%;
    height: 100%;
    float: right;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.Download-right p:first-child{
    color: #fff;
    font-size: 60px;
    margin: 0;
}


.Download-right span{
    color: rgba(255 ,255 ,255, 0.8);
    font-size: 30px;
    margin: 20px 0;
}

.Download-right button{
    border: none;
    width: 398px;
    height: 74px;
    border-radius: 12px;
    background: linear-gradient(to right,rgb(24 87 255) ,rgb(108 78 255));
    color: #fff;
    font-size: 30px;
    margin-top: 49px;
    cursor: pointer;
}

.Download-right .google-play-container {
    display: flex;
    flex-direction: column;
}

.Download-right .google-play-container .text-download{
    color: white;
    font-size: 16px;
    text-decoration: underline;
    margin-top: 10px;
}

.Download-right .google-play-container .google-play-icon {
    margin-top: 49px;
    width: 287px !important;
    height: 86px !important;
}

.Download-right .Android {
    width: 284px;
    font-size: 20px;
    height: 40px;
    
    background:none;
}

.info-item{
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 14px; */
}

.info-item img{
    width: 40px !important;
    height: 40px !important;
}

.info-item p{
    color: #fff;
    font-size: 18px;
    margin:0;
}

.top-layout img{
    width: 600px;
    height: 600px;
    display: flex;
    align-items: center;
}


.download-layout{
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.download-layout p{
    width: 40%;
    text-align: center;
    font-size: 18px;
    color: rgb(28 28 32);
    margin-top: 35px;
}

.downloadlist{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 55px;
}



.download-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    height: 165px;
    border: 2px solid rgba(135, 128, 128, 0.2);
    border-radius: 12px;
    margin: 0 47px;
    cursor: pointer;
    opacity: 0.5;
}

.download-item:hover{
    opacity: 1;
}

.download-item img{
    width: 90px;
    height: 90px;
    margin-top: 12px;
    
}

.download-item span{
    margin: 0;
    font-size: 24px;
    color: rgb(28 28 32)
}

.viplayout{
    margin-top: 50px;
    background-color: rgb(247 248 255);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.viplayout p{
    font-weight: 500;
    margin: 50px 0;
    font-size: 48px;
    color: rgb(28 28 32);
}

.viplist{
   
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}


.viplist2{
    display: grid;
    justify-content: center;
    grid-template-columns: 312px 312px 312px;
    grid-template-rows: 220px 220px;
    gap: 32px;
    margin-bottom: 32px;
}

.vip-item{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 312px;
    height: 220px;
    margin-right: 32px;
    margin-bottom: 32px;
    border-radius: 25px;
}

.vip-item img{
    width: 110px;
    height: 110px;
}

.vip-item span{
    color: rgb(28 28 32);
    font-size: 24px;
    text-align: center;
}

.step-layput{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.step-layput p{
    font-weight: 500;
    margin-top: 50px;
    margin-bottom: 10px;
    font-size: 48px;
    color: rgb(28 28 32);
}

.step-layput span{
    font-size: 18px;
    color: rgb(28 28 32);
}

.step-count{
    background-color: rgb(61 121 252);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 25px;
    font-weight: 600;
    margin-bottom: 15px;
}



