
  .swiper-s {
    height: 380px !important ;
    width: 400px !important;
    text-align: center;
    margin-right: -100px !important;
  }


.Member{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #fff; */
   
}


.Member p{
    color: rgb(28 28 32);
    font-size: 48px;
}

.pay{
    width: 100%;
}

.pay-item{
    width: 248px;
    height: 280px;
    /* background-color: rgba(29, 53, 60, 1); */
    border-radius: 30px 90px 30px 30px;
    border: 3px solid rgba(203 ,216 ,235,1);
    display: flex;
    flex-direction: column;  
    align-items: center; 
    margin: 0 30px;
    cursor: pointer;
  
}

.pay-item p{
    margin: 0;
    color:rgba(27 28 29);
    font-size:30px;
    text-align: center;
    font-weight: bold;
    
}

.pay-item p span{
    font-size: 78px;
    color:rgba(27 28 29);
}

.pay-item-active{
    width: 248px;
    height: 280px;
    background-color: #EBF1FF;
    border-radius: 30px 90px 30px 30px;
    display: flex;
    flex-direction: column;  
    align-items: center; 
    margin: 0 30px;
    border: 3px solid rgba(61, 121, 252, 1);
    cursor: pointer;

}

.pay-item-active p{
    margin: 0;
    color:rgba(27 28 29);
    font-size:30px;
    text-align: center;
    font-weight: bold;
}

.pay-item-active p span{
    font-size: 78px;
    color:rgba(27 28 29);
}


.discount{
    width: 192px;
    height: 60px;
    background-image: linear-gradient(to right ,rgba(24, 87, 255,1) , rgba(108, 78, 255,1) );
    border-radius: 37px;
    font-size: 22px;
    color: #fff;
    text-align: center;
    line-height: 60px;
}

.recom{
    line-height: 48px;
    width: 100%;
    height: 48px;
    display: flex;    
    z-index: 999;

    position: absolute;
    right: -74px;
    top: 24px;
}
.recom-txt{
    color: #fff;
    font-size: 30px;
    padding: 0 14px;
    border-radius: 6px 150px 150px 6px;
    background-image: linear-gradient(to right,  rgb( 255 95 135), rgb(255 146 96));
}

.pay-method{
    background-color: rgba(19, 25, 34,1) ;
    width: 1316px;
    height: 400px;
    border-radius: 20px;
    margin-top: 64px;
    margin-bottom: 88px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.pay-method p{
    margin: 0 0 75px 0;

}

.pay-content{
    display: flex;
    align-items: center;
   
}

.pay-content img{
    width: 90px;
    height: 90px;
}

.pay-content span{
    color: #fff;
    font-size: 20px;
}

.pay-method-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 100px;
}
