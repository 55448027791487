.H5dwonload {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(247 248 255);
}

.H5dwonload button {
    background: linear-gradient(to right, rgb(24 87 255), rgb(108 78 255));
    width: 290px;
    height: 54px;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    margin-top: -26px;
}

.H5dwonload .google-play-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.H5dwonload .google-play-container .text-download{
    color: #3d79fc;
    font-size: 16px;
    text-decoration: underline;
    margin-top: 10px;
}

.H5dwonload .google-play-container .google-play-icon {
    margin-top: 49px;
    width: 287px !important;
    height: 86px !important;
}

.H5dwonload-top {
    width: 100%;
    height: 459px;
    background-image: url(../assets/h5/d-top-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: rgb(247 248 255);
    display: flex;
    flex-direction: column;
}

.H5dwonload-top p {
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    margin: 20px 0 0 20px;
}

.H5dwonload-top span {
    color: rgb(247 248 255);
    font-size: 16px;
    margin: 8px 0 0 20px;

}


.info-list {
    margin: 8px 0 0 20px;
    display: flex;
    flex-direction: column;
}


.info-list-item {
    display: flex;
    align-items: center;
    margin-top: 4px;

}

.info-list-item img {
    width: 22px;
    height: 22px;
}

.info-list-item p {
    color: #fff;
    font-size: 12px;
    margin: 0;
}

.h5download-content {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.h5download-content span {
    color: rgba(28, 28, 32, 0.8);
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
}

.h5download-content p {
    color: rgb(28 28 32);
    font-size: 24px;
    font-weight: bold;
}

.platform {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.platform-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 35px;
}

.platform-list-item {
    width: 91px;
    height: 100px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 7px;
    border: 1px solid rgba(135, 128, 128, 0.2);

}

.platform-list-item img {
    width: 56px;
    height: 56px;

}

.platform-list-item span {
    font-size: 16px;
    color: rgb(28 28 32);
}

.title {
    height: 58px;
    line-height: 58px;
    text-align: center;
    color: rgba(27, 28, 29, 0.8);
    font-size: 16px;
    font-weight: 500;
}

.h5vip-list {
    display: grid;
    grid-template-columns: 168px 168px;
    grid-template-rows: 126px 126px 126px;
    gap: 10px;
}

.h5vip-list-item {
    background-color: #fff;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.h5vip-list-item img {
    width: 62px;
    height: 62px;
}

.h5vip-list-item p {
    color: rgb(28 28 32);
    font-size: 14px;
    font-weight: 400 !important;
    text-align: center;
}

.h5vip-step{
    display: flex;
    align-items: center;
}

.h5vip-step-index{
  width: 264px;
  height: 15px;
}

.h5vip-step img{
    width: 40px;
    height: 40px;
    margin: 0 44px;

}

.h5vip-step p{
    width: 24%;
    font-size: 14px;
    color: rgb(28 28 32);
    font-weight: 400;
    text-align: center;
    margin: 12px 0;

}