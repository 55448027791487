.H5bottom {
    width: 100%;
    /* position: fixed;
    bottom: 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
}


.downlist {
    width: 100%;
    height: 221px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: solid rgba(255, 255, 255, 0.1) 1px;
}

.downlist-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33%;
    height: 110px;
}

.downlist-item img {
    width: 40px;
    height: 40px;
}


.downlist-item span {
    color: rgb(28 28 32);
    font-size: 12px;
    font-weight: 500;
}

.connect-txt {
    background-color: rgba(247, 248, 255, 1);
    height: 48px;
    width: 100%;
    font-size: 16px;
    color: rgba(27, 28, 29, 0.8);
    font-weight: 600;
    line-height: 48px;
    text-align: center;
}

.connect-list {
    height: 180px;
    width: 100%;
    background-color: rgba(247, 248, 255, 1);
    display: flex;
    justify-content: space-evenly;

}

.connect-list-item {
    background-color: #fff;
    width: 109px;
    height: 132px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.connect-list-item img {
    width: 40px;
    height: 40px;
}

.connect-list-item p {
    color: rgb(28 28 32);
    font-weight: bold;
    font-size: 12px;
    margin: 0;
}

.connect-list-item span {
    display: inline-block;
    max-width: 85%;
    /* 限制宽度以避免超出父容器 */
    color: rgb(28 28 32);
    font-size: 10px;
    overflow: hidden;
    /* 隐藏超出部分 */
    white-space: nowrap;
    /* 禁止换行 */
    text-overflow: ellipsis;
    /* 显示省略号 */
    text-align: center;
}

.connect-list-item button {
    background-color: rgb(61 121 252);
    height: 20px;
    border-radius: 8px;
    color: #fff;
    font-size: 8px;
    padding: 0 10px;
    border: none;
}

.bottom {
    width: 100%;
    height: 98px;
    background-color: rgba(28, 34, 43, 1);

}

.bottom p {
    color: #fff;
    font-size: 12px;
}

.txt-btn {
    height: 32px;
    display: flex;
    align-items: center;
    margin-left: 28px;
    margin-top: 20px;
}

.txt-btn p {
    color: #fff;
    font-size: 14px;
    white-space: pre;
}

.noinfo {
    line-height: 32px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    margin-left: 28px;

}